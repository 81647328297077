import React from "react"

export default ({ children }) => (
<footer className="page-footer teal lighten-2">
  <div className="footer-copyright">
    <div className="container">
    © 2020 Juniper Swift LLC
    </div>
  </div>
</footer>
)
